import React, { useState, createContext, useContext, useEffect } from "react";

// AuthContextInterface는 인증 상태와 로그인, 로그아웃 함수들을 정의합니다.
interface AuthContextInterface {
  isAuthenticated: boolean; // 사용자가 인증되었는지를 나타내는 상태
  login: () => void; // 사용자를 로그인시키는 함수
  logout: () => void; // 사용자를 로그아웃시키는 함수
}

// AuthContext를 생성하며, 초기값은 undefined로 설정하여 기본 상태를 정의합니다.
export const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

// useAuth는 AuthContext를 사용하여 현재 인증 상태와 로그인/로그아웃 함수에 접근할 수 있게 합니다.
export const useAuth = () => {
  return useContext(AuthContext); // AuthContext를 사용하여 인증 상태와 함수를 반환
};

// AuthProvider는 인증 상태와 관련된 로직을 제공하는 컴포넌트입니다.
const AuthProvider: React.FC = ({ children }) => {
  // 초기 인증 상태는 localStorage에서 'access_token'이 존재하는지를 확인하여 결정됩니다.
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));

  useEffect(() => {
    // localStorage의 변경을 감지하여 인증 상태를 업데이트합니다.
    const handleStorageChange = () => {
      setIsAuthenticated(!!localStorage.getItem('access_token')); // 토큰이 존재하면 인증된 상태로 설정
    };

    // 'storage' 이벤트 리스너를 등록하여 다른 탭에서 localStorage가 변경될 때 이를 감지합니다.
    window.addEventListener('storage', handleStorageChange);

    // 컴포넌트가 언마운트될 때 'storage' 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // 빈 배열([])로 설정하여 컴포넌트가 마운트될 때 한 번만 실행

  // login 함수는 인증 상태를 true로 설정합니다.
  const login = () => {
    setIsAuthenticated(true);
  };

  // logout 함수는 인증 상태를 false로 설정하고, localStorage에서 'access_token'을 제거합니다.
  const logout = () => {
    alert("로그아웃")
    setIsAuthenticated(false); // 인증 상태를 false로 설정
    localStorage.removeItem('access_token'); // 로그아웃 시 토큰을 삭제
  };

  // AuthContext.Provider를 사용하여 자식 컴포넌트들에게 인증 상태와 함수들을 전달합니다.
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children} {/* 자식 컴포넌트들을 렌더링 */}
    </AuthContext.Provider>
  );
};

export { AuthProvider }; // AuthProvider 컴포넌트를 외부에서 사용할 수 있도록 내보냅니다.
