import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDbvttYlGnbgpVDHxD-dxruKxF5B36awSs",
  authDomain: "greybox-web-dde75.firebaseapp.com",
  projectId: "greybox-web-dde75",
  storageBucket: "greybox-web-dde75.appspot.com",
  messagingSenderId: "32326257757",
  appId: "1:32326257757:web:418288eb995633b997d1b4",
  measurementId: "G-789XBZ98VK"
};

initializeApp(firebaseConfig);

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
