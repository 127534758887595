import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/AdminSidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

import { useAuth } from 'src/AuthProvider';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Components
const LoginForm = Loader(lazy(() => import('src/components/Login/LoginForm')));
const Template = Loader(lazy(() => import('src/content/applications/Template')));
const Frame = Loader(lazy(() => import('src/content/applications/Frame')));
const User = Loader(lazy(() => import('src/content/applications/User')));
const DashBoard = Loader(lazy(() => import('src/content/applications/DashBoard')));
const AddTemplate = Loader(lazy(() => import('src/content/applications/AddTemplate')));
const AddFrame = Loader(lazy(() => import('src/content/applications/AddFrame')));
const TopBottom = Loader(lazy(() => import('src/content/applications/TopBottom')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));
const SignupForm = Loader(lazy(() => import('src/components/Signup/SignupForm')));

const ProtectedSidebarLayout: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <SidebarLayout /> : <Navigate replace to="/login" />;
};

const routes: RouteObject[] = [
  {
    path: 'login',
    element: <LoginForm />
  },
  {
    path: 'signup',
    element: <SignupForm />
  },
  
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate replace to="/template"></Navigate> // 루트 경로에서 인증 상태에 따라 리디렉션
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '',
    element: <ProtectedSidebarLayout />,  // admin 경로 전체에 대해 인증 보호 적용
    children: [
      {
        path: 'template',
        element: <Template /> // template 경로에서 Stat 컴포넌트 렌더링
      },
      {
        path: 'frame',
        element: <Frame /> // frame 경로에서 Stat 컴포넌트 렌더링
      },
      {
        path: 'user',
        element: <User /> // frame 경로에서 Stat 컴포넌트 렌더링
      },
      {
        path: 'dashboard',
        element: <DashBoard /> // frame 경로에서 Stat 컴포넌트 렌더링
      },
      {
        path: 'add-template',
        element: <AddTemplate /> // template 경로에서 Stat 컴포넌트 렌더링
      },
      {
        path: 'add-frame',
        element: <AddFrame /> // template 경로에서 Stat 컴포넌트 렌더링
      },
      {
        path: 'watermark',
        element: <TopBottom /> // template 경로에서 Stat 컴포넌트 렌더링
      },
      {
        path: '*',
        element: <Navigate replace to="/template" />  // admin 하위 경로가 존재하지 않을 때 template로 리디렉션
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />  // 모든 경로에 대한 fallback 404 페이지
  }
];

export default routes;
